<template>
<div class="modal-wrapper">
    <div class="modal ku-error-modal" :class="cls" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content">
                <div class="modal-header">
                    <i class="fas fa-times close-btn" @click="closed"></i>
                    <slot></slot>
                </div>
                <div class="modal-body">
                    <div class="pdf-container">
                        <pdf class="pdf-embed" :page="page" :key="page" v-for="page in [1,2,3]" :src="'pdf/programs/'+url">
                            <template v-slot:loading>
                                <div class="loader">
                                    <i class="fas fa-spinner"></i>
                                </div>
                            </template>
                        </pdf>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>

import pdf from 'pdfvuer'

export default {
    components: { pdf }, 
    data() {
        return {
            cls: '',
        }
    },
    props: {
        url:{
            type: String,
            default: null
        }
    },
    methods: {
        closed(){
            this.$emit('closed');
        }
    },
    mounted() {
        document.body.className = 'modal-body-wrapper';
    },
     unmounted(){
         document.body.className = '';
     }
}
</script>
<style lang="scss">
    .modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00000080;
        z-index: 99999;
    }
</style>
<style lang="scss" scoped>

@keyframes spinner {
    100% {
        transform: rotate(360deg)
    }
}

.ku-error-modal {
    display: block;
    .modal-body, .modal-header {
        padding: 10px;
    }
    .modal-header{
        border-bottom: none;
        justify-content: center;
        padding-top: 0px;
        flex-direction: column;
        padding-bottom: 0;
        .close-btn {
            font-size:1.5rem;
            margin-top: 10px;
            margin-left: 98%;
        }
    }
    .modal-dialog {
        max-width: 58%;
        .modal-content{
            border-radius: 1px;
            text-align: center;
            background: rgb(146,145,145);
            .fas {
                cursor:pointer;
            } 
            .pdf-container{
                height:55rem;
                overflow-y:scroll;
                overflow-x:hidden;
                .pdf-embed {
                    .loader {
                        height:450px;
                        margin-top: 40%;
                        i {
                            font-size: 2.5rem;
                            animation: spinner 1s linear;
                        }
                    }
                }
            }
        }
    }   
    &.closed {
        display: none !important;
    }
}

</style>