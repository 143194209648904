<template>
    <ResourceVisorModal v-if="pdfURL" :url="pdfURL" @closed="pdfURL=null"></ResourceVisorModal>
    <main class="obscure-wrapper">
        <section class="container programs-section main">
            <div class="row">
                <div class="row">   
                    <div class="col">
                        <div class="programs-header">
                            <h2>Bienvenido(a) {{ getUser.info.firstName }}</h2>
                        </div>
                    </div>
                </div>
                <div class="col programs">
                    <div class="row">
                        <course :unlocked="true" col-size="4" v-for="(item, index) in programs" :program="item" :key="index" @openpdf="openResourceVisor"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="footer-controls">
                        <Button black @click="$router.push('/programs')">Agregar programa</Button>
                        <Button black @click="$router.push('/logout')">Cerrar sesión</Button>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<style lang="scss" scoped>
.programs-section { 
    margin-bottom: 100px;
    .footer-controls {
        text-align: right;
        padding-top:20px;
    }
}
</style>
<script>
import ResourceVisorModal from '@/components/modals/ResourceVisor'
import Button from '../components/global/Button.vue';

export default {
    components: { Button, ResourceVisorModal },
    data() {
        return {
            programs: [],
            pdfURL: null
        }
    },
    watch: {
    },
    async mounted() {
        await this.restartSection();
    },
    computed: {
        getUser() {
            return this.$store.state.user || {}
        }
    },
    methods: {
        async restartSection() {
            this.$loading = true;
            try {
                let req = await this.$kuAPI.getUserProfile();
                this.programs = req.data;
                if (this.programs.length == 0)
                    this.$router.push('/programs');
            } catch (error) {
                console.error(error);
            }
            this.$loading = false;
        },
        openResourceVisor(e) {
            this.pdfURL = e
        }
    }
}
</script>