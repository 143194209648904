<template>
<ErrorModal title='Cupo limitado.' v-if="showErrorModal" @closed="showErrorModal = false" message="No es posible realizar la compra de este programa ya que se agotaron los lugares disponibles." >
    <img class="modal-icon" src="../assets/images/icn-curso-no-disponible.png">
</ErrorModal>
<ResourceVisorModal v-if="pdfURL" :url="pdfURL" @closed="pdfURL=null"></ResourceVisorModal>
    <main class="obscure-wrapper">
        <section class="container programs-section main">
            <div class="row">
                <div class="row">   
                    <div class="col">
                        <div class="programs-header">
                            <h2>Programas disponibles</h2>
                            <p v-if="programs.length">Haz clic sobre un programa para añadirlo a tu compra:</p>
                            
                        </div>
                    </div>
                </div>
                <div class="col-md-8 programs" ref="cartWrapper">
                    <div class="row">
                        <div class="col empty-prog" v-if="isEmpty">
                            Por el momento no existen programas disponibles, por favor intenta más tarde.
                        </div>
                        <course :emit-event="!allowToBuy" @selected="courseSelected" col-size="6" v-for="(item, index) in programs" :program="item" :key="index"  @openpdf="openResourceVisor"/>
                    </div>
                </div>
                <div class="col-md-4 cartWrapper">
                    <div v-if="!isSticky && showCart">
                        <shopping-cart />
                        <div class="footer-controls">
                            <Button @click="next" :disabled="!canBuy">SIGUIENTE</Button>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="isEmpty">
                    <div class="col">
                        <Button class="my-programs-btn" black @click="$router.push('/my-programs')">IR A MI PERFIL</Button>
                    </div>
                </div>
            </div>
        </section>
        <div class="sticky" v-if="isSticky && showCart">
            <div class="container">
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-4">
                        <shopping-cart />
                        <div class="footer-controls">
                            <Button @click="next" :disabled="!canBuy">SIGUIENTE</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<style lang="scss" scoped>
.my-programs-btn{
    float: right;
    margin-top: 140px;
}
.empty-prog {
    margin-top: 40px;
}

.programs {
    position: relative;
    z-index: 2;
}

.cartWrapper{
    position: relative;
    & > div {
        position: block;
        width: 100%;
    }
}
.sticky {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}

</style>
<script>
import ErrorModal from '@/components/modals/Error'
import ResourceVisorModal from '@/components/modals/ResourceVisor'

export default {
    components: { ErrorModal, ResourceVisorModal },
    data() {
        return {
            windowWidth: window.innerWidth,
            programs: [],
            scrollY: 0,
            cartOgTop: 0,
            cartTop: 0,
            showErrorModal: false,
            allowToBuy: true,
            pdfURL: null,
        }
    },
    watch: {
    },
    async mounted() {
        await this.restartSection();
        this.cartOgTop = this.$refs.cartWrapper.getBoundingClientRect().top + window.scrollY;
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.onResize);
    },
    beforeUnmount() { 
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('resize', this.onResize); 
    },
    computed: {
        showCart() {
            return this.programs.length;
        },
        canBuy(){
            return this.$store.state.shoppingCart.length > 0;
        },
        isEmpty(){
            return !this.programs.length && !this.$loading;
        },
        isSticky(){
            if (this.windowWidth<960) return false;
            const newTop = this.cartOgTop - this.scrollY;

            if (newTop >= 0) return false;
            return true;

        },
        getUser() {
            return this.$store.state.user || {}
        },
        isKnotion() {
            return this.$store.state.user.info.idUserKnotionInt != null
        }
    },
    methods: {
        onScroll() {
            this.scrollY = Math.round(window.scrollY);
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        openResourceVisor(e) {
            this.pdfURL = e
        },
        courseSelected() {
            this.showErrorModal = true;
        },
        async restartSection() {
            this.$loading = true;
            try {
                let response = await this.$kuAPI.getProgramsToBuy();
                this.allowToBuy = response.data.allowToBuy;
                this.programs = response.data.programs;
            } catch (error) {
                console.error(error);
            }
            
            this.$loading = false;
        },
        next() {
            this.$router.push('/checkout')
        }
    }
}
</script>